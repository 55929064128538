
























































































































































import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { Input, Select, Option } from "element-ui";
import { CropVariable, CropVariety, Crop, Checkbox } from "@/store/models/cropVariety";

@Component({
  components: { Input, Select, Option }
})
export default class DynamicField extends Vue {
  @Inject("validator") $validator: any;
  selectedcheckbox = [] as any;
  flag = false;
  all = [] as any;
  checkboxes = {
    name: "",
    selected: true
  } as Checkbox;
  mix: Checkbox[] = [] as Checkbox[];
  seen: boolean = true;
  selected: boolean = true;
  obj: string = "";
  count: number = 0;
  @Prop({ default: () => [] })
  // public meta!: Crop;
  public meta!: { [key: string]: CropVariable[] };

  // op(all: any) {
  //   this.all = all;
  //   this.selectedcheckbox = all.split(",");
  // }
  // kl(obj: string) {
  //   this.obj = obj;
  // }

  // get check() {
  //   this.flag = false;
  //   for (let i = 0; i < this.selectedcheckbox.length; i++) {
  //     if (this.obj === this.selectedcheckbox[i]) {
  //       this.flag = true;
  //       break;
  //     }
  //   }
  //   return this.flag;
  // }
  //
  // qwert(all: any) {
  //   let temp;
  //   this.all = all;
  //   console.log(all);
  //   for (const j of this.all) {
  //     temp = {
  //       name: j,
  //       selected: false
  //     };
  //     this.mix.push(temp);
  //   }
  //   return this.mix;
  // console.log(this.mix);
  // }

  // metaKey(all:any, selected:string) {
  //   console.log('yes')
  //   let temp;
  //   this.all = all;
  //   this.seen = false;
  //   this.selectedcheckbox = selected.split(",");
  //
  //   for (const i in this.all) {
  //     for (const j of this.selectedcheckbox) {
  //       if (this.all[i] == j) {
  //         temp = {
  //           'name': this.all[i],
  //           'selected': true
  //         };
  //         // this.mix.push(temp);
  //         this.all.splice(i, 1);
  //       }
  //     }
  //   }
  //
  //   for (const j of this.all) {
  //     temp = {
  //       'name': j,
  //       'selected': false
  //     };
  //     this.mix.push(temp);
  //   }
  //   // return this.mix;
  // }

  created() {}

  getValidator(meta: CropVariable) {
    let validator: any = {};
    if (meta.required != undefined) {
      validator["required"] = meta.required;
    }
    if (meta.validationregex != undefined) {
      validator["vregex"] = meta.validationregex;
    }
    // if (meta.min != undefined) {
    //   validator["min_value"] = meta.min;
    // }
    // if (meta.max != undefined) {
    //   validator["max_value"] = meta.max;
    // }
    console.log(validator);
    return validator;
  }

  getError(meta: CropVariable) {
    let error = this.$validator.errors.first(meta.cropvarietiesmetavarname);
    if (error == "test") {
      return meta.validationmessage;
    }
    return error;
  }
}
